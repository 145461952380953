import React from 'react'
import { Link } from 'react-router-dom'

type Props = {}

const NotFound: React.FC<Props> = () => (
  <div>
    <h1>Page not found</h1>
    <Link to='/'>Return to home page</Link>
  </div>
)

export default React.memo(NotFound)
