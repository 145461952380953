import { Icon, Link, List, ListItem, Navbar, Page, Panel, Searchbar } from 'konsta/react'
import {
  MdMenu,
  MdMenuOpen,
  MdOutlineHome,
  MdOutlineLogout,
  MdOutlinePhone,
  MdOutlineHub,
  MdOutlineQuestionAnswer,
  MdSearch
} from 'react-icons/md'
import React, { useEffect, useId, useRef, useState } from 'react'
import { navTo } from '../../browserHistory'
import { useLocation } from 'react-router'
import { PUBLIC_ROUTES } from '../../config'
import { useAppConfig } from '../../store/appConfig'
import { logOut } from '../../services/auth'

interface NavProps {
  hasSearch?: boolean
  onSearch?: (value: string) => void
}
export const NavDrawer = ({ hasSearch, onSearch }: NavProps) => {
  const location = useLocation()
  const { resetAppConfig } = useAppConfig()
  const searchRef = useId()
  const [leftPanelOpened, setLeftPanelOpened] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const handleSearch = (e: any) => {
    setSearchQuery(e.target.value)
    onSearch && onSearch(e.target.value)
  }

  if (PUBLIC_ROUTES.includes(location.pathname)) {
    return <></>
  }

  const logout = () => {
    setLeftPanelOpened(false)
    resetAppConfig()
    logOut()
    navTo('/auth')
  }

  return (
    <>
      <Navbar
        title='NterNow Z-Wave Install Tool'
        left={
          <Link navbar iconOnly onClick={() => setLeftPanelOpened(true)}>
            <Icon ios={<MdMenu className='w-7 h-7' />} material={<MdMenu className='w-6 h-6' />} />
          </Link>
        }
        subnavbar={
          hasSearch && (
            <Searchbar
              inputMode={'numeric'}
              inputId={searchRef}
              onInput={handleSearch}
              value={searchQuery}
              onClear={() => {
                setSearchQuery('')
                onSearch && onSearch('')
              }}
            />
          )
        }
      />
      <Panel side='left' opened={leftPanelOpened} onBackdropClick={() => setLeftPanelOpened(false)}>
        <Page>
          <Navbar
            title='NterNow'
            right={
              <Link navbar onClick={() => setLeftPanelOpened(false)}>
                <Icon ios={<MdMenuOpen className='w-7 h-7' />} material={<MdMenuOpen className='w-6 h-6' />} />
              </Link>
            }
          />
          <List strongIos outlineIos>
            <ListItem
              onClick={() => {
                setLeftPanelOpened(false)
                navTo('/')
              }}
              title={
                <p className='flex items-center text-sm'>
                  <MdOutlineHome className='w-4 h-4 mr-1'></MdOutlineHome>Home
                </p>
              }
              link
            />
            <ListItem
              onClick={() => {
                setLeftPanelOpened(false)
                navTo('/servers')
              }}
              title={
                <p className='flex items-center text-sm'>
                  <MdOutlineHub className='w-4 h-4 mr-1'></MdOutlineHub>Hubs
                </p>
              }
              link
            />
            <ListItem
              title={
                <p className='flex items-center text-sm'>
                  <MdOutlinePhone className='w-4 h-4 mr-1'></MdOutlinePhone>Live Help
                </p>
              }
              href={'tel:844-531-1283'}
              link
            />
            <ListItem
              onClick={() => {
                setLeftPanelOpened(false)
                window.open(
                  'https://nternow.freshdesk.com/support/solutions/articles/63000263650-smart-home-connections-faq',
                  '_blank'
                )
              }}
              title={
                <p className='flex items-center text-xs'>
                  <MdOutlineQuestionAnswer className='w-4 h-4 mr-1'></MdOutlineQuestionAnswer>FAQ
                </p>
              }
              link
            />
            <ListItem
              onClick={() => logout()}
              title={
                <p className='flex items-center text-sm text-red-500'>
                  <MdOutlineLogout className='w-4 h-4 mr-1'></MdOutlineLogout>Logout
                </p>
              }
              chevronIcon={<></>}
              link
            />
          </List>
        </Page>
      </Panel>
    </>
  )
}
