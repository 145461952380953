import { Button, Dialog, DialogButton, Link, List, ListItem, Navbar, Page, Panel, ListInput } from 'konsta/react'
import React, { useMemo } from 'react'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import { FaTrash } from 'react-icons/fa'
import { FaEdit } from 'react-icons/fa'
import { useHubSocketContext } from '../../../../providers/hubsocket'
import { DialogAddingCode } from './DialogAddingCode'
import { CommandEnum } from '../../../../constants/socket'
import { UserCode, UserCodeValue } from '../../../../constants/ezlo.constant'
import { PopupCustom } from '../../../../components/PopupCustom'

interface Props {
  popupOpened: boolean
  setPopupOpened: (value: boolean) => void
}

export function UserCodesPopup({ popupOpened, setPopupOpened }: Props) {
  const { lockProperties, sendMessage } = useHubSocketContext()
  const [confirmRemoveOpened, setConfirmRemoveOpened] = React.useState<boolean>(false)
  const [createOpened, setCreateOpened] = React.useState<boolean>(false)
  const [selectedCode, setSelectedCode] = React.useState<UserCode>()
  const userCodes = useMemo(() => {
    return Object.keys(lockProperties?.userCodes || {})
  }, [lockProperties?.userCodes])

  const handleRemoveCode = () => {
    sendMessage(CommandEnum.DictionaryRemove, {
      _id: lockProperties?.userCodeId,
      key: selectedCode?.key
    })
    setSelectedCode(undefined)
  }

  return (
    <PopupCustom side='left' className='w-full' opened={popupOpened} onBackdropClick={() => setPopupOpened(false)}>
      <Page>
        <Navbar
          title='Secure Codes'
          left={
            <Link className='text-sm' navbar onClick={() => setPopupOpened(false)}>
              <MdKeyboardArrowLeft size='30' />
            </Link>
          }
        />
        <List strongIos outlineIos>
          {userCodes.map((key, index) => {
            const item = lockProperties?.userCodes[key]
            return (
              <ListItem
                key={index}
                title={<p className='flex items-center text-sm'>{key + '. ' + item?.name}</p>}
                after={
                  <div className='flex items-center gap-3'>
                    <div>{item?.code}</div>
                    <FaTrash
                      onClick={() => {
                        setSelectedCode(item)
                        setConfirmRemoveOpened(true)
                      }}
                      className='cursor-pointer w-3 h-3 text-red-600'
                    />
                    <FaEdit
                      onClick={() => {
                        setSelectedCode(item)
                        setCreateOpened(true)
                      }}
                      className='cursor-pointer w-3 h-3 text-gray-600'
                    />
                  </div>
                }
              />
            )
          })}
        </List>
        <Button
          onClick={() => {
            setSelectedCode(undefined)
            setCreateOpened(true)
          }}
          large
        >
          New code
        </Button>
      </Page>
      <Dialog
        opened={confirmRemoveOpened}
        onBackdropClick={() => setConfirmRemoveOpened(false)}
        title='Confirm'
        content={`Do you want to remove code ${selectedCode?.code}?`}
        buttons={
          <>
            <DialogButton onClick={() => setConfirmRemoveOpened(false)}>No</DialogButton>
            <DialogButton
              strong
              onClick={() => {
                setConfirmRemoveOpened(false)
                handleRemoveCode()
              }}
            >
              Yes
            </DialogButton>
          </>
        }
      />
      <DialogAddingCode userCode={selectedCode} createOpened={createOpened} setCreateOpened={setCreateOpened} />
    </PopupCustom>
  )
}
