import crypto from 'crypto-browserify'

const SALT = 'oZ7QE6LcLJp6fiWzdqZc'
// Generate SHA1Password
export const generateSha1Password = (username: string, password: string) => {
  const sha1Password = crypto
    .createHash('sha1')
    .update(username.toLowerCase())
    .update(password)
    .update(SALT) //Salt
    .digest('hex')

  const sha1PasswordCS = crypto
    .createHash('sha1')
    .update(username)
    .update(password)
    .update(SALT) //Salt
    .digest('hex')

  return {
    sha1Password,
    sha1PasswordCS
  }
}
