// Countdown.tsx
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'

type CountdownProps = {
  initialTimeInSeconds: number
  onTimeUp?: () => void
}

const Countdown = forwardRef((props: CountdownProps, ref) => {
  const { initialTimeInSeconds, onTimeUp } = props
  const [secondsRemaining, setSecondsRemaining] = useState<number>(initialTimeInSeconds)

  useEffect(() => {
    if (secondsRemaining > 0) {
      const timer = setTimeout(() => setSecondsRemaining(secondsRemaining - 1), 1000)
      return () => clearTimeout(timer)
    } else if (onTimeUp) {
      onTimeUp()
    }
  }, [secondsRemaining, onTimeUp])

  useImperativeHandle(ref, () => ({
    resetCountdown: () => {
      setSecondsRemaining(initialTimeInSeconds)
    }
  }))

  // Convert seconds to mm:ss format
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
      .toString()
      .padStart(2, '0')
    const secs = (seconds % 60).toString().padStart(2, '0')
    return `${minutes}:${secs}`
  }

  return (
    <div className='flex justify-center items-center'>
      <span className='font-bold text-gray-800'>
        {secondsRemaining > 0 ? formatTime(secondsRemaining) : "Time's Up!"}
      </span>
    </div>
  )
})

export default Countdown
