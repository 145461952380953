import React, { useEffect, useState } from 'react'
import { Block, Button, Toast } from 'konsta/react'
import './Home.scss'
import { DeviceList } from './components/DeviceList'
import { useAppConfig } from '../../store/appConfig'
import { useHubServer } from '../../hooks/useHubServer'
import { Actions, ConnectStatus } from '../../constants/socket'
import { AddLockDevice } from './components/AddLockDevice'
import { HubSocketProvider, useHubSocketContext } from '../../providers/hubsocket'
import { FabActions } from './components/FabActions'
import { BroadcastMessagePopup } from './components/BroadcastMessagePopup'
import { ExcludeDevice } from './components/ExcludeDevice'
import { ZwaveReset } from './components/ZwaveReset'
import { NavDrawer } from '../../components/NavDrawer/NavDrawer'

const HomeContainer = () => {
  const { deviceId } = useAppConfig()
  const { hubServer } = useHubServer(deviceId)
  const [showBroadcastMessage, setShowBroadcastMessage] = useState<boolean>(false)
  const {
    init,
    connectStatus,
    broadcastMessages,
    action,
    sendAction,
    onBroadcast,
    isDeviceLoading,
    loadDevices,
    devices
  } = useHubSocketContext()

  useEffect(() => {
    if (hubServer) {
      init(hubServer)
    }
  }, [hubServer])

  useEffect(() => {
    if (connectStatus == ConnectStatus.Connected) {
      loadDevices()
    }
  }, [connectStatus])

  useEffect(() => {
    // Reload device when broadcast update
    const off = onBroadcast((data: any) => {
      if (['hub.device.added', 'hub.device.removed'].includes(data.data.msg_subclass)) {
        loadDevices()
      }
    })
    return () => off && off()
  }, [])

  return (
    <>
      <NavDrawer />
      {connectStatus === ConnectStatus.Connecting && (
        <Block>
          <p className='text-gray-800'>
            Connecting to Hub <span className='text-primary'>#{deviceId}</span> ...
          </p>
        </Block>
      )}
      {connectStatus === ConnectStatus.Failed && (
        <Block>
          <p className='text-red-800'>The hub is currently offline or experiencing network issues</p>
          <br />
          <Button onClick={() => hubServer && init(hubServer)} large>
            Retry
          </Button>
        </Block>
      )}
      {connectStatus === ConnectStatus.Connected && (
        <>
          <Block>
            <p className='text-gray-800'>
              Connected to Hub <span className='text-primary'>#{deviceId}</span>
            </p>
          </Block>
          {devices && <DeviceList devices={devices} />}
          <Block className='space-y-4'>
            <Button disabled={isDeviceLoading} onClick={() => loadDevices()} large>
              {isDeviceLoading ? 'Loading...' : 'Refresh'}
            </Button>
          </Block>
          <Toast
            position='center'
            opened={broadcastMessages.length > 0 && !showBroadcastMessage}
            button={
              <Button rounded clear small inline onClick={() => setShowBroadcastMessage(true)}>
                More
              </Button>
            }
          >
            <div className='shrink'>{broadcastMessages[0]?.message}</div>
          </Toast>
          {action === Actions.ADD_LOCK && (
            <AddLockDevice open={action === Actions.ADD_LOCK} close={() => sendAction(undefined)} />
          )}
          <ZwaveReset
            open={!!action && [Actions.ZWAVE_RESET, Actions.HUB_REBOOT].includes(action)}
            close={() => sendAction(undefined)}
          />
          <ExcludeDevice open={action === Actions.EXCLUDE_LOCK} close={() => sendAction(undefined)} />
          <BroadcastMessagePopup
            popupOpened={showBroadcastMessage}
            messages={broadcastMessages}
            setPopupOpened={setShowBroadcastMessage}
          />
          <FabActions />
        </>
      )}
    </>
  )
}

export const Home = () => (
  <HubSocketProvider>
    <HomeContainer />
  </HubSocketProvider>
)
