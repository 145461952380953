import { Link, Navbar, Page } from 'konsta/react'
import React from 'react'
import { BroadcastMessage } from './BroadcastMessage'
import { PopupCustom } from '../../../components/PopupCustom'

interface Props {
  messages: any[]
  popupOpened: boolean
  setPopupOpened: (value: boolean) => void
}

export function BroadcastMessagePopup({ messages, popupOpened, setPopupOpened }: Props) {
  return (
    <PopupCustom opened={popupOpened} onBackdropClick={() => setPopupOpened(false)}>
      <Page>
        <Navbar
          title='Events'
          right={
            <Link className='text-sm' navbar onClick={() => setPopupOpened(false)}>
              Close
            </Link>
          }
        />
        <BroadcastMessage />
      </Page>
    </PopupCustom>
  )
}
