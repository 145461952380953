import React from 'react'
import { Block, Preloader } from 'konsta/react'
import { useAppConfig } from '../store/appConfig'
import { navTo } from '../browserHistory'
import { PUBLIC_ROUTES } from '../config'
import { useLocation } from 'react-router'

interface Props {
  children: React.ReactNode
}
export function AuthenticationProvider({ children }: Props) {
  const location = useLocation()
  const isLoading = false
  const { loginType } = useAppConfig()

  if (!loginType && !PUBLIC_ROUTES.includes(location.pathname)) {
    navTo('/auth')
    return null
  }

  if (isLoading) {
    return (
      <Block strong insetMaterial outlineIos className='text-center'>
        <Preloader />
      </Block>
    )
  }

  return <>{children}</>
}
