// Countdown.tsx
import React from 'react'
import { Toggle } from 'konsta/react'

type ToggleLabelProps = {
  onLabel: string
  offLabel: string
  checked: boolean
  className?: string
  rootClassName?: string
  setToggle: (toggle: boolean) => void
}

export const ToggleLabel = (props: ToggleLabelProps) => {
  return (
    <div className={`text-center text-xs text-gray-800 ${props.rootClassName}`}>
      <Toggle
        component='span'
        className={`ml-2 m-2 mx-auto text-right ${props.className}`}
        checked={props.checked}
        readOnly
        onClick={() => props.setToggle(!props.checked)}
      />
      <p className='text-center text-xs'>{props.checked ? props.onLabel : props.offLabel}</p>
    </div>
  )
}
