import { Dialog, DialogButton, List, ListInput } from 'konsta/react'
import React, { useEffect, useMemo } from 'react'
import { useHubSocketContext } from '../../../../providers/hubsocket'
import { Controller, useForm } from 'react-hook-form'
import { BroadcastMsgSubclass, CommandEnum, UserCode } from '../../../../constants/ezlo.constant'

interface DialogAddingCodeProps {
  createOpened: boolean
  setCreateOpened: (value: boolean) => void
  userCode?: UserCode
}
export const DialogAddingCode = ({ createOpened, setCreateOpened, userCode }: DialogAddingCodeProps) => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const { lockProperties, sendMessage, onBroadcast } = useHubSocketContext()
  const { control, setValue, handleSubmit, trigger } = useForm({
    values: {
      code: userCode?.code || '',
      name: userCode?.name || '',
      key: userCode?.key || (lockProperties?.maxUserCodeKey ? lockProperties.maxUserCodeKey + 1 : 1)
    }
  }) // Initialize useForm

  // Listen broadcast message to turn off
  useEffect(() => {
    // Reload device when broadcast update
    const off = onBroadcast((data: any) => {
      if (data.data.msg_subclass === BroadcastMsgSubclass.DictionaryUpdated) {
        setLoading(false)
        setCreateOpened(false)
      }
    })
    return () => off && off()
  }, [])

  const addNewCode = (data: any) => {
    console.log('new code', data)
    setLoading(true)
    sendMessage(CommandEnum.DictionarySet, {
      _id: lockProperties?.userCodeId,
      key: String(data.key),
      element: {
        type: 'userCode',
        value: {
          code: data.code,
          name: data.name
        }
      }
    })
  }

  const isEdit = useMemo(() => {
    return !!userCode?.key
  }, [!!userCode?.key])

  return (
    <Dialog
      opened={createOpened}
      onBackdropClick={() => setCreateOpened(false)}
      title={isEdit ? 'Edit code' : 'New code'}
      content={
        <form id='hook-form-new-code' onSubmit={handleSubmit(addNewCode)}>
          {loading && (
            <>
              <div className='absolute z-40 w-full h-full left-0 top-0 bg-black bg-opacity-50 duration-400'></div>
              <div className='flex flex-col items-center absolute top-0 right-0 w-full h-full'>
                <p className='text-gray-300 mt-[60%] z-[100]'>Creating....</p>
              </div>
            </>
          )}
          <List className='flex flex-shrink text-left'>
            <Controller
              control={control}
              name='key'
              disabled={!!userCode?.key}
              render={({ field }) => (
                <ListInput
                  floatingLabel
                  label='Key'
                  type='number'
                  required
                  autoComplete={'off'}
                  placeholder='Key'
                  {...field} // Spread the field props
                />
              )}
            />
            <Controller
              control={control}
              name='name'
              render={({ field }) => (
                <ListInput
                  floatingLabel
                  label='Name'
                  type='text'
                  required
                  autoComplete={'off'}
                  placeholder='name'
                  {...field} // Spread the field props
                />
              )}
            />
            <Controller
              control={control}
              name='code'
              render={({ field }) => (
                <ListInput
                  floatingLabel
                  label='Code'
                  type='number'
                  required
                  autoComplete={'off'}
                  placeholder='Code'
                  {...field} // Spread the field props
                />
              )}
            />
          </List>
        </form>
      }
      buttons={
        <>
          <DialogButton
            onClick={() => {
              setCreateOpened(false)
              setLoading(false)
            }}
          >
            No
          </DialogButton>
          <DialogButton>
            <button disabled={loading} type='submit' form='hook-form-new-code'>
              Yes
            </button>
          </DialogButton>
        </>
      }
    />
  )
}
