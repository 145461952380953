export enum DeviceCategoryType {
  SIREN = 'siren',
  DOOR_LOCK = 'door_lock'
}
export interface Device {
  _id: string
  batteryPowered: boolean
  category: DeviceCategoryType
  deviceTypeId: string
  gatewayId: string
  info: Info
  name: string
  parentDeviceId: string
  persistent: boolean
  reachable: boolean
  ready: boolean
  roomId: string
  security: string
  status: string
  subcategory: string
  type: string
  armed?: boolean
  firmware?: Firmware[]
}

export interface Info {
  'firmware.stack'?: string
  hardware?: string
  manufacturer?: string
  model?: string
  protocol?: string
  'zwave.node'?: string
  'zwave.smartstart'?: string
}

export interface Firmware {
  id: string
  version: string
}

export const DEAD_DEVICE_ID = '0_0_0'
