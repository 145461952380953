const storage = window.localStorage

export const addItem = (key: string, value: string): void => {
  storage.setItem(key, value)
}

export const getItem = (key: string): string => storage.getItem(key) || ''

export const removeItem = (key: string): void => {
  storage.removeItem(key)
}

export const clear = (): void => {
  storage.clear()
}
