import axios, { AxiosRequestConfig, AxiosResponse, AxiosError, AxiosInstance } from 'axios'
import * as storage from './storage'
import { LOGIN_TYPE, MMS_AUTH_HEADER, MMS_AUTH_SIG_HEADER } from '../constants/auth'
import { getToken } from './auth'
import { generateSha1Password } from '../utils/sha1'
const BASE_URL = 'https://vera-us-oem-account11.mios.com'
export const defaultHeaders = {
  'Content-Type': 'application/json'
}

export class EzloMss {
  instance: AxiosInstance
  static ezloInstance: EzloMss
  constructor() {
    const config: AxiosRequestConfig = {
      headers: defaultHeaders,
      baseURL: process.env.REACT_APP_API_CLIENT_API
    }
    this.instance = axios.create(config)
    // this.loadInterceptors()
  }

  public static getInstance() {
    if (EzloMss.ezloInstance) {
      return EzloMss.ezloInstance
    }
    EzloMss.ezloInstance = new EzloMss()
    return EzloMss.ezloInstance
  }

  loadInterceptors() {
    const onFulfilled = (response: AxiosResponse): AxiosResponse => response
    const onRejected = (error: AxiosError): Promise<AxiosError> => {
      const codes: Array<number> = [401]
      if (error.response && codes.includes(error.response.status)) {
        storage.clear()
        window.location.reload()
      }
      return Promise.reject(error)
    }

    this.instance.interceptors.response.use(onFulfilled, onRejected)
    this.instance.interceptors.request.use((config) => {
      const loginAuth = getToken()
      if (loginAuth.loginType == LOGIN_TYPE.EZLO_MSS && loginAuth.token) {
        const mmsToken = loginAuth.token.split('|')
        config.headers[MMS_AUTH_HEADER] = mmsToken[0]
        config.headers[MMS_AUTH_SIG_HEADER] = mmsToken[1]
      }
      return config
    })
  }

  async login(username: string, password: string) {
    const { sha1PasswordCS } = generateSha1Password(username, password)
    return this.instance.post('hubs/zwave-install/login', {
      username,
      shaPassword: sha1PasswordCS
    })
  }
}
