import { Link, List, ListItem, Navbar, Page } from 'konsta/react'
import React from 'react'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import { useHubSocketContext } from '../../../providers/hubsocket'
import { UserCodesPopup } from './UserCodesPopup/UserCodesPopup'
import { PopupCustom } from '../../../components/PopupCustom'

interface Props {
  popupOpened: boolean
  setPopupOpened: (value: boolean) => void
}

export function DeviceDetailPopup({ popupOpened, setPopupOpened }: Props) {
  const { lockProperties, lockDeviceInfo } = useHubSocketContext()
  const [openUserCodes, setOpenUserCodes] = React.useState<boolean>(false)

  const listItems = [
    {
      title: 'Name',
      value: lockDeviceInfo?.name
    },
    {
      title: 'Battery',
      value: `${lockProperties?.battery}%`
    },
    {
      title: 'Manufacturer',
      value: lockDeviceInfo?.manufacturer
    },
    {
      title: 'Model',
      value: lockDeviceInfo?.model
    },
    {
      title: 'Secure Codes',
      link: true,
      onClick: () => setOpenUserCodes(true)
    }
  ]

  return (
    <>
      <PopupCustom className='w-full' opened={popupOpened} onBackdropClick={() => setPopupOpened(false)}>
        <Page>
          <Navbar
            title='Device Detail'
            left={
              <Link className='text-sm' navbar onClick={() => setPopupOpened(false)}>
                <MdKeyboardArrowLeft size='30' />
              </Link>
            }
          />
          <List strongIos outlineIos>
            {listItems.map((item, index) => (
              <ListItem
                key={index}
                title={<p className='flex items-center text-sm'>{item.title}</p>}
                onClick={item.onClick}
                after={<div className='flex items-center text-xs'>{item.value}</div>}
                link={item.link}
              />
            ))}
          </List>
        </Page>
      </PopupCustom>
      <UserCodesPopup popupOpened={openUserCodes} setPopupOpened={setOpenUserCodes} />
    </>
  )
}
