import React, { useState } from 'react'
import { Block, Button, List, ListInput, Toast } from 'konsta/react'
import NternowLogo from '../../assets/nternow-logo.svg'
import { EzloMss } from '../../services/ezloMss'
import { LoginType, useAppConfig } from '../../store/appConfig'
import { navTo } from '../../browserHistory'
import { useForm, Controller } from 'react-hook-form' // Import react-hook-form

export function Login() {
  const { setAppConfig } = useAppConfig()
  const [errorMsg, setErrorMsg] = useState<string>()
  const { control, handleSubmit } = useForm() // Initialize useForm

  const loginAction = (data: any) => {
    // Use the form data
    const { username, passwordX } = data

    EzloMss.getInstance()
      .login(username, passwordX)
      .then((item) => {
        setAppConfig({
          token: item.data.token,
          loginType: LoginType.client,
          hubAccountId: username
        })
        navTo('/servers')
      })
      .catch((e) => {
        setErrorMsg('Wrong username or password')
      })
  }

  return (
    <Block className='justify-center items-center max-w-sm m-auto mt-40'>
      <NternowLogo className='text-center w-48 m-auto mb-5' />
      <p className='text-primary text-2xl font-bold text-center m-auto'>Welcome to NterNow Z-Wave Install Tool</p>
      <form autoComplete='off' onSubmit={handleSubmit(loginAction)}>
        {' '}
        {/* Add form and handleSubmit */}
        <List insetIos>
          <Controller
            control={control}
            name='username'
            render={({ field }) => (
              <ListInput
                label='Username'
                type='text'
                required
                autoComplete={'off'}
                placeholder='Username'
                {...field} // Spread the field props
              />
            )}
          />
          <Controller
            control={control}
            name='passwordX'
            render={({ field }) => (
              <ListInput
                label='Password'
                type='password'
                required
                autoComplete={'new-password'}
                placeholder='Your password'
                {...field} // Spread the field props
              />
            )}
          />
        </List>
        <Button large>
          {' '}
          {/* Change to type submit */}
          Login
        </Button>
      </form>
      <Toast
        position='center'
        opened={!!errorMsg}
        button={
          <Button rounded clear small inline onClick={() => setErrorMsg(undefined)}>
            Close
          </Button>
        }
      >
        <div className='shrink'>{errorMsg}</div>
      </Toast>
    </Block>
  )
}
