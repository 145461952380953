import { Badge, Link, List, ListItem } from 'konsta/react'
import React, { useEffect, useState } from 'react'
import { MdAddHome, MdDoorFront, MdLock, MdKeyboardArrowRight } from 'react-icons/md'
import { DEAD_DEVICE_ID, Device, DeviceCategoryType } from '../../../types/Ezlo/Device'
import { ToggleLabel } from '../../../components/ToggleLabel/ToggleLable'
import { useHubSocketContext } from '../../../providers/hubsocket'
import { DoorLockEnum } from '../../../constants/ezlo.constant'
import { DeviceDetailPopup } from './DeviceDetailPopup'

interface Props {
  devices?: Device[]
}

const Icons = {
  [DeviceCategoryType.SIREN]: MdDoorFront,
  [DeviceCategoryType.DOOR_LOCK]: MdLock
}
export function DeviceList({ devices }: Props) {
  const { lockProperties, lockUnlockDoor } = useHubSocketContext()
  const [lock, unlock] = useState<boolean>(false)
  const [popupOpened, setPopupOpened] = useState<boolean>(false)

  useEffect(() => {
    unlock(lockProperties?.status === DoorLockEnum.Secured)
  }, [lockProperties?.status])

  const handleLock = (lock: boolean) => {
    unlock(lock)
    lockUnlockDoor(lock ? DoorLockEnum.Secured : DoorLockEnum.Unsecured)
  }

  return (
    <>
      <DeviceDetailPopup popupOpened={popupOpened} setPopupOpened={setPopupOpened} />

      <List>
        {devices?.map((item) => {
          const Icon = Icons[item.category] || MdAddHome
          return (
            <ListItem
              key={item._id}
              media={<Icon size='28' />}
              title={
                <>
                  {item.name} <br />{' '}
                  {item.deviceTypeId === DEAD_DEVICE_ID && (
                    <Badge colors={{ bg: 'bg-red-600' }}>Zwave Pair Failure: Exclude and Add device again</Badge>
                  )}
                </>
              }
              after={
                <div className='flex items-center'>
                  {lockProperties && item.deviceTypeId !== DEAD_DEVICE_ID && (
                    <>
                      <ToggleLabel
                        rootClassName='mb-[-18px]'
                        className='k-color-brand-red'
                        checked={lock}
                        setToggle={() => handleLock(!lock)}
                        onLabel='LOCKED'
                        offLabel='UNLOCKED'
                      />
                      <Link onClick={() => setPopupOpened(true)} className='flex bg-gray-300 ml-2 rounded-full'>
                        <MdKeyboardArrowRight className='text-gray-500' size='30' />
                      </Link>
                    </>
                  )}
                </div>
              }
            />
          )
        })}
      </List>
    </>
  )
}
