import _ from 'lodash'
export const buildMessage = (method: string, params: object, id?: string) => ({
  method,
  params,
  id: id || String(new Date().getTime())
})

const mappingRules = [
  {
    conditions: {
      msg_subclass: 'hub.extensions.plugin.run.progress'
    },
    output: 'Zwave: <%= result.operationId %> - <%= _.startCase(result.status) %>, <%= result.completed %>% Completed'
  },
  {
    conditions: {
      msg_subclass: 'hub.extensions.plugin.ui_broadcast',
      'result.event': 'include_device_progress'
    },
    output: '<%= _.startCase(result.plugin) %>: <%= _.startCase(result.event) %>, <%= result.value %>% Complete'
  },
  {
    conditions: {
      'result.error.data': 'hub.gateway.zwave.err.unknown'
    },
    output: 'Gateway Unknown error'
  },
  // For hub.device
  {
    conditions: {
      msg_subclass: 'hub.device.removed'
    },
    output: 'Hub.Device: Device removed'
  },
  {
    conditions: {
      msg_subclass: 'hub.device.updated'
    },
    output: 'Hub.Device: Device updated'
  },
  {
    conditions: {
      msg_subclass: 'hub.device.added'
    },
    output: 'Hub.Device: Device added'
  },
  {
    conditions: {
      'result.event': 's2_select_authentication_modes'
    },
    output: 'Zwave: S2 Authentication Modes Selection - [Access Control, Authenticated, Unauthenticated]'
  },
  {
    conditions: {
      'result.plugin': 'zwave',
      'result.event': 'module_reset'
    },
    output: 'Zwave: <%= _.startCase(result.event) %> - <%= _.startCase(result.status) %>'
  },
  {
    conditions: {
      'result.plugin': 'zwave'
    },
    output: 'Zwave: <%= _.startCase(result.event) %>'
  }
  // ... Add more rules as needed
]

const ERROR_CODE: { [key: string]: string } = {
  '1': 'No error, request sent',
  '0': 'No error',
  '-1': 'General error',
  '-2': 'Invalid argument',
  '-3': 'Bad alloc',
  '-4': 'Timer expired',
  '-5': 'Resource busy',
  '-6': 'Communication error on send',
  '-7': 'End point is unreachable',
  '-8': 'Not found',
  '-9': 'Cannot be completed immediately',
  '-10': 'Not supported for current version CC',
  '-11': 'Not possible to transmit data because network is busy',
  '-12': 'Invalid callback',
  '-13': 'Invalid result',
  '-14': 'The controller is primary but not a SIS',
  '-15': 'Report waiting has expired',
  '-16': 'Finish values getting due to reported fail',
  '-17': 'CommandClassValuesSetter: request value error',
  '-18': 'DiscoverHandler, request node info fail',
  '-19': 'TimeManager, request failed, setup default node time diff',
  '-20': 'Could not request node info',
  '-21': 'Request of NIF has been timed out',
  '-22': 'Invalid Node Info',
  '-23': 'Node already exists',
  '-24': 'Could not find node info',
  '-25': 'Finalize interview on cc processing due to reported fail',
  '-26': 'Security bootstrapping of a Smart Start node has failed',
  '-27': 'Cannot open backup session',
  '-28': 'Wrong status of opening backup session',
  '-29': 'Backup session closing failed',
  '-30': 'Backup session closing wrong status',
  '-31': 'Close session request failed',
  '-32': 'Read backup data failed',
  '-33': 'Wrong backup reading status',
  '-34': 'Cannot start reading backup data',
  '-35': 'Write backup data request failed',
  '-36': 'Wrong backup writing status',
  '-37': 'Cannot send writing data request to chip',
  '-38': 'Backup writing error, EOF expected but N bytes have been read',
  '-39': 'Open backup restoring session failed',
  '-40': 'Wrong status on backup restoring session opening',
  '-41': 'Cannot start backup restoring: setdefault raw failed',
  '-42': 'Controller has no permissions to start include'
}

export const formatMessage = (data: any) => {
  const errCode = _.get(data, 'result.error.code')
  if (errCode) {
    return `${_.startCase(data.result.plugin)}: ${ERROR_CODE[String(errCode)]}` || 'Gateway Unknown error'
  }
  const matchedRule = _.find(mappingRules, (rule) =>
    _.every(rule.conditions, (value, key) => _.get(data, key) === value)
  )
  if (matchedRule) {
    // Use the lodash template function to interpolate the values into the template
    const templateFn = _.template(matchedRule.output)
    return templateFn(data)
  }

  return _.startCase(_.get(data, 'msg_subclass', 'Unknown'))
}
