import format from 'date-fns/format'

export const defaultDateFormat = 'MM/dd/yyyy'
export const timeFormat = 'HH:mm'

export function formatDate(dateString: string | Date | null, formatString: string = defaultDateFormat): string {
  if (!dateString) {
    return ''
  }

  try {
    return format(new Date(dateString), formatString)
  } catch (e) {
    return ''
  }
}
