export const AUTH_TOKEN = 'auth_token'
export const AUTH_TYPE_KEY = 'auth_type'
export const AUTH_USER = 'auth_user'
export const AUTH_HEADER = 'X-Auth-Token'
export const MMS_AUTH_HEADER = 'Mmsauth'
export const MMS_AUTH_SIG_HEADER = 'Mmsauthsig'
export enum LOGIN_TYPE {
  'NTERNOW' = 'nternow',
  'EZLO_MSS' = 'ezlo_mss'
}
