import { Actions as KActions, ActionsButton, ActionsGroup, Fab } from 'konsta/react'
import React, { useState } from 'react'
import { MdAddBox, MdBuild, MdChangeCircle, MdDelete, MdLogout } from 'react-icons/md'
import { Device } from '../../../types/Ezlo/Device'
import { useHubSocketContext } from '../../../providers/hubsocket'
import { Actions } from '../../../constants/socket'
import { navTo } from '../../../browserHistory'

interface Props {
  devices?: Device[]
}

export function FabActions({}: Props) {
  const { sendAction } = useHubSocketContext()
  const [popoverOpened, setPopoverOpened] = useState(false)

  const sendMessage = (action: Actions) => {
    sendAction(action)
    setPopoverOpened(false)
  }

  return (
    <>
      <KActions className='z-50' opened={popoverOpened} onBackdropClick={() => setPopoverOpened(false)}>
        <ActionsGroup>
          <ActionsButton fontSizeIos='10' fontSizeMaterial='10' onClick={() => sendMessage(Actions.ADD_LOCK)}>
            <MdAddBox className='mr-2' /> Add device
          </ActionsButton>
          <ActionsButton fontSizeIos='10' fontSizeMaterial='10' onClick={() => sendMessage(Actions.EXCLUDE_LOCK)}>
            <MdDelete className='mr-2' /> Exclude device
          </ActionsButton>
          <ActionsButton fontSizeIos='10' fontSizeMaterial='10' onClick={() => sendMessage(Actions.ZWAVE_RESET)}>
            <MdChangeCircle className='mr-2' /> Zwave reset
          </ActionsButton>
          <ActionsButton fontSizeIos='10' fontSizeMaterial='10' onClick={() => sendMessage(Actions.HUB_REBOOT)}>
            <MdChangeCircle className='mr-2' /> Hub Reboot
          </ActionsButton>
          <ActionsButton
            className='text-gray-500'
            fontSizeIos='10'
            fontSizeMaterial='10'
            onClick={() => navTo('/servers')}
          >
            <MdLogout className='mr-2' /> Disconnect Hub
          </ActionsButton>
        </ActionsGroup>
        <ActionsGroup>
          <ActionsButton
            colors={{ textIos: 'md-dark-primary' }}
            fontSizeIos='10'
            fontSizeMaterial='10'
            onClick={() => setPopoverOpened(false)}
          >
            Cancel
          </ActionsButton>
        </ActionsGroup>
      </KActions>
      <Fab
        onClick={() => setPopoverOpened(true)}
        className='absolute bg-green-700 hover:bg-green-600 right-4-safe bottom-20-safe z-40'
        icon={<MdBuild />}
      />
    </>
  )
}
