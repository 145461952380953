import React, { useMemo, useState } from 'react'
import { formatDate } from '../../../utils/date'
import { useHubSocketContext } from '../../../providers/hubsocket'
import JsonViewer from '../../../components/JsonViewer'
import { Toolbar } from 'konsta/react'
import { ToggleLabel } from '../../../components/ToggleLabel/ToggleLable'
import { filterLogs, isFilterOut } from './helpers'

interface BroadcastMessageItemProps {
  item: any
  toggle: boolean
}

const LABEL_COLOR: { [key: string]: string } = {
  'Hub.Device': 'bg-green-600',
  Zwave: 'bg-blue-600'
}

function BroadcastMessageItem({ item, toggle }: BroadcastMessageItemProps) {
  const [open, setOpen] = useState<boolean>(false)
  const isOpen = open || toggle
  const list = item.message.split(':')
  const label = list.length >= 2 ? list[0] : undefined
  const message = list.length >= 2 ? list[1] : list[0]

  return (
    <li className={`group ${isFilterOut(item) ? 'bg-green-100' : ''}`} key={item.data.msg_id}>
      <div className='p-4 pb-0 flex justify-between items-center cursor-pointer' onClick={() => setOpen(!open)}>
        <div className='w-full'>
          <p className='text-xs text-gray-600 leading-5'>
            {label && <span className={`${LABEL_COLOR[label]} px-2 py-1 rounded text-white`}>{label}</span>} {message}
          </p>
        </div>
        <span className='text-gray-400 text-sm'>{formatDate(item.timestamp, 'HH:mm:ss')}</span>
        <svg
          className={`h-5 w-5 text-gray-400 transform transition-transform duration-200 ${isOpen ? '' : 'rotate-90'}`}
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 20 20'
          fill='currentColor'
        >
          <path
            fillRule='evenodd'
            d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
            clipRule='evenodd'
          />
        </svg>
      </div>
      <div className='pl-2 pr-2 pb-2 text-gray-400 text-sm mt-2 bg-gray-50'>
        {isOpen && <JsonViewer _currentDepth={0} json={item.data} />}
        {/*<pre className='text-sm max-w-full overflow-ellipsis overflow-hidden'>*/}
        {/*  {mapShow.includes(item.data.msg_id) &&*/}
        {/*    JSON.stringify(*/}
        {/*      {*/}
        {/*        id: 'ui_broadcast',*/}
        {/*        msg_id: '655f627d222a48158369e0a5',*/}
        {/*        msg_subclass: 'hub.extensions.plugin.run.progress',*/}
        {/*        result: {*/}
        {/*          completed: 10,*/}
        {/*          error: null,*/}
        {/*          operationId: '123222',*/}
        {/*          status: 'started'*/}
        {/*        }*/}
        {/*      },*/}
        {/*      null,*/}
        {/*      1*/}
        {/*    )}*/}
        {/*</pre>*/}
      </div>
    </li>
  )
}

export function BroadcastMessage() {
  const { broadcastMessages } = useHubSocketContext()
  const [toggle, setToggle] = useState<boolean>(false)
  const [toggleMoreLogs, setToggleMoreLogs] = useState<boolean>(false)
  const logs = useMemo(() => {
    return toggleMoreLogs ? broadcastMessages : filterLogs(broadcastMessages)
  }, [toggleMoreLogs, broadcastMessages])

  return (
    <>
      <Toolbar
        top={true}
        innerClassName='!overflow-visible flex-row-reverse'
        className={`left-0 h-20 ios:top-11-safe material:top-14-safe sticky w-full`}
      >
        <div className='flex flex-row-reverse mt-5'>
          <ToggleLabel
            rootClassName='ml-10'
            checked={toggle}
            setToggle={setToggle}
            onLabel='Show Details'
            offLabel='Hide Details'
          />
          <ToggleLabel
            checked={toggleMoreLogs}
            setToggle={setToggleMoreLogs}
            onLabel='More logs'
            offLabel='Less logs'
          />
        </div>
      </Toolbar>
      <div className='bg-gray-100 pl-4 pr-4 min-h-screen mt-2'>
        {/*<div className='flex flex-row-reverse mb-4 sticky'>*/}
        {/*  <ToggleLabel*/}
        {/*    className='ml-10'*/}
        {/*    checked={toggle}*/}
        {/*    setToggle={setToggle}*/}
        {/*    onLabel='Show Details'*/}
        {/*    offLabel='Hide Details'*/}
        {/*  />*/}
        {/*  <ToggleLabel*/}
        {/*    checked={toggleMoreLogs}*/}
        {/*    setToggle={setToggleMoreLogs}*/}
        {/*    onLabel='More logs'*/}
        {/*    offLabel='Less logs'*/}
        {/*  />*/}
        {/*</div>*/}
        <div className='bg-white rounded-md shadow-md max-w-md mx-auto'>
          <ul className='divide-y divide-gray-300'>
            {logs?.map((item: any) => <BroadcastMessageItem key={item.data.msg_id} toggle={toggle} item={item} />)}
          </ul>
        </div>
      </div>
    </>
  )
}
