/*
Misc
custom

Devices
hub.devices.list
hub.device.name.set
hub.device.room.set
hub.device.armed.set
hub.device.force_remove

-- Duplicated
hub.device.setting.value.set
hub.device.settings.list
hub.device.settings.list -- filtered by device ID

Settings
hub.device.setting.value.set
hub.device.settings.list
hub.device.settings.list -- filtered by device ID

Items
hub.items.list
hub.item.value.set
hub.items.list -- filtered by device Id

Rooms -> no need
hub.room.list
hub.room.create
hub.room.name.set
hub.room.order.set
hub.room.get
hub.room.delete
hub.room.all.delete

House Modes
hub.modes.get
hub.modes.current.get
hub.modes.switch
hub.modes.cancel_switch
hub.modes.switch_to_delay_set
hub.modes.alarm_delay_set
hub.modes.notification.set

Hub
hub.info.get
hub.reset
hub.reboot

Plugins
hub.extensions.plugin.run -- Zwave
hub.extensions.plugin.run -- Zigbee
hub.extensions.plugin.run -- 2GIG

Scenes -> no need
hub.scenes.get
hub.scenes.list
hub.scenes.delete
hub.scenes.blocks.list
hub.scenes.enabled.set
hub.scenes.notification.add
hub.scenes.notification.remove
hub.scenes.run
hub.scenes.room.set
hub.scenes.time.list
hub.scenes.trigger.devices.list
*/

export enum CommandEnum {
  HubDevicesList = 'hub.devices.list',
  HubItemsList = 'hub.items.list',
  HubReboot = 'hub.reboot',
  HubNetworkGet = 'hub.network.get',
  HubDeviceCheck = 'hub.device.check',
  DictionaryRemove = 'hub.item.dictionary.value.remove',
  DictionarySet = 'hub.item.dictionary.value.set',
  HubNetworkReconnect = 'hub.network.modem.try_connect'
}

export enum InputType {
  Text = 'text',
  ListText = 'list_text',
  MixType = 'mix_type',
  Checkbox = 'checkbox',
  Number = 'number',
  Textarea = 'textarea',
  CustomRunZWave = 'custom:run:zwave',
  CustomRunZigbee = 'custom:run:zigbee',
  CustomRun2GIG = 'custom:run:2gig'
}

export interface InputParams {
  name: string
  type: InputType
  required?: boolean
  default?: string | boolean
}

export interface CommandModel {
  group?: boolean
  name: string
  value: string
  method?: string
  customize?: boolean
  params?: InputParams[]
}

export const CommandList: CommandModel[] = [
  { name: 'Misc', group: true, value: 'misc' },
  {
    name: 'custom',
    value: 'custom',
    customize: true,
    params: [{ name: 'payload', type: InputType.Textarea, required: true }]
  },

  /*
    Devices
    hub.devices.list
    hub.device.name.set
    hub.device.room.set
    hub.device.armed.set -> ignore
    hub.device.force_remove

    // Duplicated
    hub.device.setting.value.set
    hub.device.settings.list
    hub.device.settings.list -- filtered by device ID
    */
  { name: 'Devices', group: true, value: 'devices' },
  {
    name: 'hub.devices.list',
    value: CommandEnum.HubDevicesList
  },
  {
    name: 'hub.device.name.set',
    value: 'hub.device.name.set',
    params: [
      { name: '_id', type: InputType.Text, required: true },
      { name: 'name', type: InputType.Text, required: true }
    ]
  },
  {
    name: 'hub.device.armed.set',
    value: 'hub.device.armed.set',
    params: [
      { name: '_id', type: InputType.Text, required: true },
      {
        name: 'armed',
        type: InputType.Checkbox,
        required: false,
        default: false
      }
    ]
  },
  {
    name: 'hub.device.force_remove',
    value: 'hub.device.force_remove',
    params: [{ name: '_id', type: InputType.Text, required: true }]
  },

  /*
  Settings
  hub.device.setting.value.set
  hub.device.settings.list
  hub.device.settings.list -- filtered by device ID
  */
  { name: 'Settings', group: true, value: 'settings' },
  {
    name: 'hub.device.setting.value.set',
    value: 'hub.device.setting.value.set',
    params: [
      { name: '_id', type: InputType.Text, required: true },
      {
        name: 'value',
        type: InputType.Number,
        required: true
      }
    ]
  },
  {
    name: 'hub.device.settings.list',
    value: 'hub.device.settings.list'
  },
  {
    name: 'hub.device.settings.list -- filtered by device ID',
    value: 'hub.device.settings.list:deviceIds',
    method: 'hub.device.settings.list',
    params: [{ name: 'deviceIds', type: InputType.ListText, required: true }]
  },

  /*
  Items => Done
  hub.items.list
  hub.item.value.set
  hub.items.list -- filtered by device Id
  */
  { name: 'Items', group: true, value: 'items' },
  {
    name: 'hub.items.list',
    value: 'hub.items.list'
  },
  {
    name: 'hub.item.value.set',
    value: 'hub.item.value.set',
    params: [
      { name: '_id', type: InputType.Text, required: true },
      { name: 'value', type: InputType.MixType, required: true }
    ]
  },
  {
    name: 'hub.items.list -- filtered by device Id',
    value: 'hub.items.list:deviceIds',
    method: 'hub.items.list',
    params: [{ name: 'deviceIds', type: InputType.ListText, required: true }]
  },
  /*
  House Modes
  hub.modes.get
  hub.modes.current.get
  hub.modes.switch
  hub.modes.cancel_switch

  // Failed
  hub.modes.switch_to_delay_set
  hub.modes.alarm_delay_set
  hub.modes.notification.set
   */
  { name: 'House Modes', group: true, value: 'house_modes' },
  {
    name: 'hub.modes.get',
    value: 'hub.modes.get'
  },
  {
    name: 'hub.modes.current.get',
    value: 'hub.modes.current.get'
  },
  {
    name: 'hub.modes.switch',
    value: 'hub.modes.switch',
    params: [{ name: 'modeId', type: InputType.Text, required: true }]
  },
  {
    name: 'hub.modes.cancel_switch',
    value: 'hub.modes.cancel_switch'
  },
  /*
  Hub
  hub.info.get
  hub.reset
  hub.reboot
   */
  { name: 'Hub', group: true, value: 'hub' },
  {
    name: 'hub.info.get',
    value: 'hub.info.get'
  },
  {
    name: 'hub.reset',
    value: 'hub.reset',
    params: [
      {
        name: 'softReset',
        type: InputType.Checkbox,
        required: false,
        default: false
      }
    ]
  },
  {
    name: 'hub.reboot',
    value: 'hub.reboot'
  },

  /*
  Plugins => Done
  hub.extensions.plugin.run -- Zwave
  hub.extensions.plugin.run -- Zigbee
  hub.extensions.plugin.run -- 2GIG
   */
  { name: 'Plugins', group: true, value: 'plugins' },
  {
    name: 'hub.extensions.plugin.run -- Zwave',
    value: 'hub.extensions.plugin.run:zwave',
    method: 'hub.extensions.plugin.run',
    params: [{ name: 'script', type: InputType.CustomRunZWave }]
  },
  {
    name: 'hub.extensions.plugin.run -- Zigbee',
    value: 'hub.extensions.plugin.run:zigbee',
    method: 'hub.extensions.plugin.run',
    params: [{ name: 'script', type: InputType.CustomRunZigbee }]
  },
  {
    name: 'hub.extensions.plugin.run -- 2GIG',
    value: 'hub.extensions.plugin.run:2gig',
    method: 'hub.extensions.plugin.run',
    params: [{ name: 'script', type: InputType.CustomRun2GIG }]
  }
]

export enum ConnectStatus {
  NotConnect = 'NotConnect',
  Connecting = 'Connecting',
  Connected = 'Connected'
}

export enum EzloResponseMethod {
  Login = 'loginUserMios',
  Registered = 'registered'
}

export enum EzloResponseId {
  LoginUser = 'loginUser',
  Register = 'register',
  UIBroadcast = 'ui_broadcast'
}

export interface DeviceCode {
  name: string
  key: string
  code: string
  mode: string
}

export interface DeviceInfo {
  id: string
  name: string
  reachable: boolean
  manufacturer: string
  model: string
  battery?: string
}

export enum EzloDeviceCategory {
  DoorLock = 'door_lock'
}

interface EzloDeviceInfo {
  manufacturer: string
  model: string
}

export enum DevicePropertyName {
  DOOR_LOCK = 'door_lock',
  USER_CODES = 'user_codes',
  LOCK_OPERATION = 'lock_operation',
  DAILY_USER_CODE_INTERVALS = 'daily_user_code_intervals',
  LIGHT_SWITCH = 'switch',
  BATTERY = 'battery',
  THERMOSTAT_MODE = 'thermostat_mode',
  TEMP = 'temp',
  THEMOSTAT_SET_HEAT = 'thermostat_setpoint_heating',
  THEMOSTAT_SET_COOL = 'thermostat_setpoint_cooling'
}

export interface EzloDevice {
  _id: string
  batteryPowered: boolean
  category: string
  deviceTypeId: string
  gatewayId: string
  name: string
  info: EzloDeviceInfo
  parentDeviceId: string
  persistent: boolean
  reachable: boolean
  ready: boolean
  roomId: string
  security: string
  status: string
  subcategory: string
  type: string
}

enum Mode {
  enabled = 'enabled',
  disabled = 'disabled'
}

export interface UserCode {
  key: string
  code: string
  mode: Mode
  name: string
}

export interface UserCodeValue {
  [key: string]: UserCode
}

export interface DeviceProperty {
  _id: string
  name: DevicePropertyName
  deviceId: string
  value?: UserCodeValue | string | number
}

export enum DoorLockEnum {
  Unsecured = 'unsecured',
  Secured = 'secured',
  Unknown = 'unknown',
  UnsecuredWithTimeout = 'unsecured_with_timeout'
}

export interface LockProperty {
  battery: number
  status: DoorLockEnum
  userCodes: UserCodeValue
  maxUserCodeKey: number
  lockTimeout?: number
  lockTimeoutEnabled?: boolean
  statusId: string
  userCodeId: string
}

export interface NetworkMobile {
  accessTechnology: string
  frequencyBand: string
  operator: string
  signalQuality: {
    level: number
    rsrp: number
    rsrq: number
    rssi: number
  }
}

export enum BroadcastMsgSubclass {
  DictionaryUpdated = 'hub.item.dictionary.updated'
}

export interface BroadcastItem {
  id: string
  msg_id?: string
  msg_subclass?: string
  result: any
}

export interface UserCodeKeyValue {
  key: string
  value: string
  name: string
}
