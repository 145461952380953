const FILTER_LIST = [
  'hub.device.setting.updated',
  'hub.info.changed',
  'hub.device.setting.added',
  'hub.network.changed'
]

export const isFilterOut = (item: any) => {
  return FILTER_LIST.includes(item.data.msg_subclass)
}
export const filterLogs = (list?: any[]) => {
  return list?.filter((item) => !isFilterOut(item))
}
