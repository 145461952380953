import React, { createContext, useContext, useEffect, useState } from 'react'
import { useSocketIo } from '../hooks/useSocketIo'
import { Actions, CommandEnum, ConnectStatus } from '../constants/socket'
import { HubServerRelay } from '../types/inputTypes/HubAccount'
import { Device, DeviceCategoryType } from '../types/Ezlo/Device'
import { extractDeviceProperties, extractLockDevice } from '../hooks/ezlo.helper'
import { BroadcastMsgSubclass, DeviceInfo, DoorLockEnum, LockProperty } from '../constants/ezlo.constant'

interface HubSocketContextType {
  connectStatus: ConnectStatus
  init: (hubServer: HubServerRelay) => void
  sendMessage: (command: CommandEnum, params: any, id?: string, func?: (data: any) => void) => void
  broadcastMessages: any[]
  action: Actions | undefined
  devices?: Device[]
  lockDeviceInfo?: DeviceInfo
  lockProperties?: LockProperty
  loadDevices: () => void
  isDeviceLoading?: boolean
  lockUnlockDoor: (status: DoorLockEnum) => void
  sendAction: (action: Actions | undefined) => void
  onBroadcast: (fn: (data: any) => void) => () => void
}

const HubSocketContext = createContext<HubSocketContextType>({
  connectStatus: ConnectStatus.NotConnect,
  init: () => {},
  sendMessage: () => {},
  broadcastMessages: [],
  devices: undefined,
  action: undefined,
  lockProperties: undefined,
  lockDeviceInfo: undefined,
  isDeviceLoading: false,
  sendAction: () => {},
  loadDevices: () => {},
  lockUnlockDoor: () => {},
  onBroadcast(fn: (data: any) => void): () => void {
    return function () {}
  }
})

interface Props {
  children: React.ReactNode
}

export const HubSocketProvider = ({ children }: Props) => {
  const { init, connectStatus, sendMessage, broadcastMessages, onBroadcast } = useSocketIo()
  const [action, setAction] = useState<Actions | undefined>()
  const [devices, setDevices] = useState<Device[]>()
  const [lockProperties, setLockProperties] = useState<LockProperty>()
  const [lockDeviceInfo, setLockDeviceInfo] = useState<DeviceInfo>()
  const [isDeviceLoading, setDeviceLoading] = useState(false)

  useEffect(() => {
    // Reload device when broadcast update
    onBroadcast((data: any) => {
      if (data.data.msg_subclass === 'hub.item.updated') {
        setLockProperties((current) => {
          if (current?.statusId === data.data.result._id) {
            return {
              ...current,
              status: data.data.result.value as DoorLockEnum
            } as LockProperty
          }
          return current
        })
      }
      if (data.data.msg_subclass === BroadcastMsgSubclass.DictionaryUpdated) {
        loadDevices()
      }
    })
  }, [])

  const loadDeviceCode = (deviceIds: string[]) => {
    sendMessage(
      CommandEnum.HubItemsList,
      {
        deviceIds
      },
      CommandEnum.HubItemsList,
      (data) => {
        const lockInfo = extractDeviceProperties(data.result.items)
        setLockProperties(lockInfo)
      }
    )
  }

  const loadDevices = () => {
    setDeviceLoading(true)
    sendMessage(CommandEnum.HubDevicesList, {}, CommandEnum.HubDevicesList, (data) => {
      setDevices(data.result.devices.filter((item: Device) => item.category === DeviceCategoryType.DOOR_LOCK))
      const look = extractLockDevice(data.result.devices)
      if (look) {
        setLockDeviceInfo(look)
        loadDeviceCode([look.id])
      }
      setDeviceLoading(false)
    })
  }

  const lockUnlockDoor = (status: DoorLockEnum) => {
    if (lockProperties) {
      sendMessage(
        CommandEnum.HubItemValueSet,
        {
          _id: lockProperties.statusId,
          value: status
        },
        CommandEnum.HubItemValueSet,
        (data) => {
          console.log('CommandEnum.HubItemValueSet', data)
        }
      )
    }
  }

  const sendAction = (action: Actions | undefined) => {
    setAction(action)
    if (!action) {
      return
    }
    switch (action) {
      case Actions.ADD_LOCK:
        sendMessage(
          CommandEnum.HubExtensionsPluginRun,
          { script: 'HUB:zwave/scripts/start_include' },
          'Start Include Lock',
          (data) => {
            console.log('exclude', data)
          }
        )
        break
      case Actions.EXCLUDE_LOCK:
        sendMessage(
          CommandEnum.HubExtensionsPluginRun,
          { script: 'HUB:zwave/scripts/start_exclude' },
          'Start Exclude Lock',
          (data) => {
            console.log('exclude', data)
          }
        )
        break
      case Actions.ZWAVE_RESET:
        sendMessage(CommandEnum.HubExtensionsPluginRun, { script: 'HUB:zwave/scripts/reset' }, 'Reset', (data) => {
          console.log('exclude', data)
        })
        break
      case Actions.HUB_REBOOT:
        sendMessage(CommandEnum.HubReboot, {}, 'Hub Reboot', (data) => {
          console.log('exclude', data)
        })
        break
    }
  }

  return (
    <HubSocketContext.Provider
      value={{
        connectStatus,
        init,
        sendMessage,
        broadcastMessages,
        action,
        sendAction,
        onBroadcast,
        devices,
        loadDevices,
        isDeviceLoading,
        lockDeviceInfo,
        lockProperties,
        lockUnlockDoor
      }}
    >
      {children}
    </HubSocketContext.Provider>
  )
}

export const useHubSocketContext = () => useContext(HubSocketContext)
