import { useQuery as useGraphQuery } from '@apollo/react-hooks'
import { HUB_SERVER_REPLAY_BY_ID } from '../graphql/queries/HubAccounts'
import { HubServerRelay } from '../types/inputTypes/HubAccount'
import sortBy from 'lodash/sortBy'
import { LoginType, useAppConfig } from '../store/appConfig'
import { useQuery } from '@tanstack/react-query'
import instance from '../services/axios'
import { useEffect, useState } from 'react'

export const useHubServer = (deviceId?: string) => {
  const { hubServers } = useAppConfig()

  return { hubServer: hubServers?.find((item) => item.hubId === deviceId) }
}

export const useHubClient = () => {
  const config = useAppConfig();
  return useQuery<HubServerRelay[]>(
    {
      queryKey: ['useHubClient'],
      enabled: config.loginType === LoginType.client,
      queryFn: () => instance.get(process.env.REACT_APP_API_CLIENT_API + '/hubs/zwave-install/ezlo-devices', {
        headers: {
          'token': config.token,
        }
      }).then(item => item.data)
    })
}

export const useHubServers = (hubAccountId?: string) => {
  const { loginType } = useAppConfig();
  const skip = loginType === LoginType.client || !hubAccountId;
  const { data: clientHubServers, refetch: refetchClient, isFetching: isFetchingClient } = useHubClient();
  const { data: hubServers, loading: loadingGraphQuery, refetch: serverRefresh } = useGraphQuery<{ getListEzloHubServerRelay: HubServerRelay[] }>(
    HUB_SERVER_REPLAY_BY_ID,
    {
      variables: { id: hubAccountId },
      fetchPolicy: 'network-only',
      skip
    }
  );

  const [loading, setLoading] = useState(loadingGraphQuery || isFetchingClient);

  const refetch = async () => {
    setLoading(true);
    if (!skip) {
      await serverRefresh();
    } else {
      await refetchClient();
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(loadingGraphQuery || isFetchingClient);
  }, [loadingGraphQuery, isFetchingClient]);

  const hubServersX = loginType === LoginType.client ? clientHubServers : hubServers?.getListEzloHubServerRelay;

  return { hubServers: sortBy(hubServersX, 'controllerStatus').reverse(), loading, refetch };
};
